import * as THREE from 'three'

const startRotation = (5 * Math.PI / 8)

class Scene {
  init (canvas) {
    this.scene = new THREE.Scene()
    this.renderer = new THREE.WebGLRenderer({ canvas, antialias: true, alpha: true })
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
    this.camera.position.set(0, 0, -10)
    this.camera.lookAt(new THREE.Vector3(0, 0, 0))

    this.mesh = new THREE.Mesh(
      new THREE.ConeBufferGeometry(5, 20, 4),
      new THREE.MeshNormalMaterial()
    )
    this.mesh.rotation.y = startRotation
    this.mesh.position.y = -5
    this.scene.add(this.mesh)

    this.animationFrameId = window.requestAnimationFrame(this.render)

    window.addEventListener('scroll', this.onScroll, { passive: true })
    window.addEventListener('resize', this.onResize, { passive: true })
  }

  dispose () {
    window.cancelAnimationFrame(this.animationFrameId)
    window.removeEventListener('scroll', this.onScroll)
    window.removeEventListener('resize', this.onResize)
  }

  render = () => {
    this.renderer.render(this.scene, this.camera)
    this.animationFrameId = window.requestAnimationFrame(this.render)
  }

  onScroll = () => {
    const scrollProgress = Math.max(0, Math.min(1, window.scrollY / (document.body.clientHeight - window.innerHeight)))
    this.camera.position.y = -(scrollProgress * 4)
    this.mesh.rotation.y = startRotation + (scrollProgress * (Math.PI / 4))
    // this.mesh.material.color.setRGB(1 - scrollProgress, 1, 1)
  }

  onResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }
}

const scene = new Scene()
export default scene
