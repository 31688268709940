import React, { useEffect, useRef } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import scene from '../components/scene'
import styles from './index.module.css'
import critFailPreview from '../images/crtifailpreview.jpg'
import illuminOceanPreview from '../images/illuminoceanpreview.jpg'
import brioPreview from '../images/briopreview.jpg'
import lihatybacPreview from '../images/lihatybacpreview.jpg'

const Project = ({ link, title, tech, image, description }) => {
  return (
    <a className={styles.project} href={link} target='_blank' rel='noreferrer'>
      <img className={styles.projectImage} src={image} alt='Project preview' />
      <div className={styles.projectInfo}>
        <div className={styles.projectTitle}>
          <h5>{title}</h5>
          <h6>{tech}</h6>
        </div>
        <p>{description}</p>
      </div>
    </a>
  )
}

const IndexPage = () => {
  const canvasEl = useRef(null)

  useEffect(() => {
    scene.init(canvasEl.current)

    return () => {
      scene.dispose()
    }
  }, [])

  return (
    <Layout>
      <SEO title='Home' />
      <div className={styles.content}>
        <div className={styles.gradient} />
        <canvas className={styles.canvas} ref={canvasEl} />
        <div className={styles.section}>
          <h1>JORDAN MUNROE</h1>
        </div>
        <div className={styles.section}>
          <h4>ABOUT</h4>
          <p>
            I am a full stack web developer based in Toronto.<br />
            Alongside web, I also delve into game development and general 3D.
          </p>
        </div>
        <div className={styles.section}>
          <h4>PROJECTS</h4>
          <Project
            link='https://briovr.com'
            title='BrioVR'
            tech='Three.js, React, Node.js, Express, PSQL, AWS'
            image={brioPreview}
            description='A web-based VR/3D presentation tool.' />
          <Project
            link='https://trmrddr.itch.io/life-is-hard-buy-a-cactus'
            title='Life is Hard, and then You Buy a Cactus'
            tech='Unity, C#'
            image={lihatybacPreview}
            description='A game created for the Ludum Dare 46 game jam.' />
          <Project
            link='https://www.critfailguild.com/'
            title='Critical Failure'
            tech='React'
            image={critFailPreview}
            description='A website for my World of Warcraft guild. Highlights our core members and accomplishments.' />
          <Project
            link='https://illuminoceans.org/'
            title='IlluminOcean'
            tech='React, Next.js'
            image={illuminOceanPreview}
            description='Freelance project for the IlluminOcean non-profit charity.' />
        </div>
        <div className={styles.section}>
          <h4>CONTACT</h4>
          <a href='mailto:jrdmunroe@gmail.com'>jrdmunroe@gmail.com</a>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
